/**
 *
 * @param data
 */
export default function printCalcQuoteWords(data) {
    this.dsClear();
    this.dsAdd('set', 'conf/popup/show', true);
    this.dsAdd('set', 'conf/popup/content', 'fileDownloadMultiple');
    this.dsAdd('set', 'conf/popup/data/state', 'load');
    this.dsAdd('set', 'conf/popup/data/buttons', []);
    this.dsProcess();

    this.filesXhr({
        action: 'printCalcQuoteWords',
        data: data,
        success: (ret) => {
            if (ret.status == true) {
                this.dsClear();
                this.dsAdd('set', 'conf/popup/data/state', 'init');
                this.dsAdd('set', 'conf/popup/data/files', ret.data);
                this.dsProcess();
            }
        },
    });
}
