export default function oppCreateCalculation(data) {
    const params = {action: "opp_create_calculation", record : data.id};

    this.load();
    this.rest.post("customAction", params, resp => {
        if(resp.errCode == "OK"){
            this.href("#detail/acm_calculation_quote/"+resp.record);
        }
        else{
            this.unLoad();
            this.alert(resp.errMsg);
        }

    });
}
