
import detailDefault from "../../detailDefault";

export default class detailacm_calculation_contracts extends detailDefault {
    load(sAction, data){
        this.setFieldsByFileType(sAction, data);
    }

    update(sAction, data){
        if (data.field === 'file_type') {
            this.setFieldsByFileType(sAction, data);
        }
    }

    setFieldsByFileType(sAction, data) {
        const fileType = sAction.dataGet(`${data.prefix}/fields/file_type/value`);

        // Schovat pole Dodavatel a Typ služby
        if (fileType === 'solution') {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/supplier/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/service_type/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/supplier/value`, null);
            sAction.dsAdd('set', `${data.prefix}/fields/service_type/value`, null);
            sAction.dsAdd('set', `${data.prefix}/changes/fields/supplier`, null);
            sAction.dsAdd('set', `${data.prefix}/changes/fields/service_type`, null);
            sAction.dsProcess();
        } else if (fileType === 'header') {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/supplier/def/visible`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/service_type/def/visible`, false);
            sAction.dsAdd('set', `${data.prefix}/fields/service_type/value`, null);
            sAction.dsAdd('set', `${data.prefix}/changes/fields/service_type`, null);
            sAction.dsProcess();
        } else {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/supplier/def/visible`, true);
            sAction.dsAdd('set', `${data.prefix}/fields/service_type/def/visible`, true);
            sAction.dsProcess();
        }
    }
}
