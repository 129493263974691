import React, {useState} from 'react';
import Loader from '../../loader';
import sAction from '../../../action';
import Button from '../../formElements/Button';

export default function FileDownloadMultiple(props) {
    const [downloaded, setDownloaded] = useState({});
    const data = props.data;
    const state = data.get('state');
    const files = data?.get("files")?.toJS();

    // sAction.param.files + "downloadFile/" + data.get("tokens");

    // Not using for now because on server it downloaded only first file
    const downloadAllFiles = () => {
        if (files) {
            files.forEach((file) => {
                window.open(`${sAction.param.files}downloadFile/${file.token}`, "_blank");
            })
            sAction.popupHide();
        }
    }

    return (
        <div className='fileDownloadMultiple'>
            <div className="acmPopupHeader">
                {sAction.translate("LBL_DOWNLOAD_MULTIPLE")}
            </div>
            {state === 'load' && <Loader />}
            {state === 'init' &&
            <>
                <div className='fileDownloadMultiple__content'>
                    {files?.map((file) => {
                        return (
                            <div
                                key={file.token}
                                className={`${downloaded[file.token] ? 'fileDownloadMultiple__link-downloaded' : ''} fileDownloadMultiple__link`}
                                onClick={() => {
                                    if (!downloaded[file.token]) {
                                        window.open(`${sAction.param.files}downloadFile/${file.token}`, "_blank");
                                        setDownloaded({...downloaded, [file.token]: true});

                                        if (Object.keys(downloaded)?.length + 1 === files?.length) {
                                            sAction.popupHide();
                                        }
                                    }
                                }}
                            >
                                {file.name}
                                <span className='icon-download' />
                            </div>
                        )
                    })}
                </div>
                {/* <Button
                    disabled={Object.keys(downloaded)?.length > 0}
                    onClick={() => downloadAllFiles()}
                    className='hoverBlue fileDownloadMultiple__button'
                >
                    <span className='icon-download buttonIcon'/>
                    {sAction.translate('LBL_DOWNLOAD_ALL_FILES')}
                </Button> */}
            </>
            }
        </div>
    )
}
